<template>
  <v-container>
    <v-card
      outlined
      rounded="lg"
    >
      <v-toolbar flat>
        <v-toolbar-title>{{ template.name }}</v-toolbar-title>
        <v-spacer />
        <div
          class="mr-2"
          v-if="template.active"
        >
          <v-btn
            @click="disableTemplate"
            color="red"
            class="my-4"
            width="135px"
            elevation="0"
            outlined
          >
            {{ $t('disable') }}
          </v-btn>
        </div>
        <div
          class="mr-2"
          v-else
        >
          <v-btn
            @click="enableTemplate"
            color="red"
            class="my-4"
            width="135px"
            elevation="0"
            outlined
          >
            {{ $t('enable') }}
          </v-btn>
        </div>
        <div>
          <v-btn
            @click="showTemplateForm"
            color="success"
            class="my-4"
            width="135px"
            elevation="0"
          >
            {{ $t('edit') }}
          </v-btn>
        </div>
      </v-toolbar>
    </v-card>
    <v-card
      outlined
      class="mb-2"
      rounded="lg"
    >
      <v-tabs v-model="tab">
        <v-tab href="#details">
          {{ $t('details') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="details">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('namespace') }}</v-list-item-title>
                <v-list-item-subtitle v-if="template.namespace">
                  {{ template.namespace }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ $t('missingVariable') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('description') }}</v-list-item-title>
                <v-list-item-subtitle v-if="template.description">
                  {{ template.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ $t('missingVariable') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('state') }}</v-list-item-title>
                <v-list-item-subtitle v-if="template.active">
                  {{ $t('active') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="!template.active">
                  {{ $t('inactive') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ $t('missingVariable') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('trigger') }}</v-list-item-title>
                <v-list-item-subtitle v-if="template.trigger">
                  {{ template.trigger }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ $t('missingVariable') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('contentType') }}</v-list-item-title>
                <v-list-item-subtitle v-if="template.contentType">
                  {{ template.contentType }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ $t('missingVariable') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('type') }}</v-list-item-title>
                <v-list-item-subtitle v-if="template.type">
                  {{ template.type }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{ $t('missingVariable') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
	name: 'TemplateProfile',
	props: {
		template: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			tab: 'details',
		}
	},
	methods: {
		showTemplateForm() {
			this.$store.commit('updateDataToMutate', {
				title: this.$t('updateTemplate'),
				objectToMutate: this.template,
				formComponent: 'components/template/TemplateForm',
				saveCallback: function (dataToUpdate) {
					this.$store.dispatch('updateCustomTemplate', dataToUpdate).then(res => {
						if(res) {
							this.$store.commit('updateActionSuccess', {
								message: this.$t('templateUpdated'),
								subMessage: this.$t('successfullyUpdatedTemplate')
							})
						} else {
							this.$store.commit('updateActionError', {
								message: this.$t('error'),
								subMessage: this.$t('somethingWentWrong')
							})
						}
					}).catch(error => {
						this.$store.commit('updateActionError', {
							message: this.$t('error'),
							subMessage: this.$t('somethingWentWrong')
						})
					})
				}
			})
		},
		enableTemplate() {
			this.$store.dispatch('enableCustomTemplate', this.template.uuid).then(res => {
				if(res) {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success'),
						subMessage: this.$t('successfullyEnabledTemplate')
					})
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('error'),
						subMessage: this.$t('couldNotEnableTemplate')
					})
				}
			}).catch(error => {
				this.$store.commit('updateActionError', {
					message: this.$t('error'),
					subMessage: this.$t('somethingWentWrong')
				})
			})
		},
		disableTemplate() {
			this.$store.dispatch('disableCustomTemplate', this.template.uuid).then(res => {
				if(res) {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success'),
						subMessage: this.$t('successfullyDisabledTemplate')
					})
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('error'),
						subMessage: this.$t('couldNotDisableTemplate')
					})
				}
			}).catch(error => {
				this.$store.commit('updateActionError', {
					message: this.$t('error'),
					subMessage: this.$t('somethingWentWrong')
				})
			})
		},
	},
	watch: {
		template(newValue) {
			this.forceRenderKey++
		}
	}
}
</script>
